let pageUrl = location.href,
    liensMenuArray = document.querySelectorAll('.nav .link');

for (let i = 0; i < liensMenuArray.length; i++) {
    const lienMenu = liensMenuArray.item(i);

    if (lienMenu.href == pageUrl || lienMenu.pathname == '/') {
        lienMenu.classList.add("active")
    } 
}
